export const L10N_DEFAULT_VALUES = {
  country: '',
  dateFormat: 'D/M/YYYY',
  displayDateFormat: 'DD/MM/YYYY',
  currency: 'INR',
  isUS: false,
  isIndia: false,
  fiscalYearEndDate: '31/03/2020',
};

export default {
  IN: {
    currency: 'INR',
    isIndia: true,
  },

  US: {
    dateFormat: 'M/D/YYYY',
    displayDateFormat: 'MM/DD/YYYY',
    currency: 'USD',
    fiscalYearEndDate: '12/31/2020',
    isUS: true,
  },

  SG: {
    currency: 'SGD',
  },
};
