import React from 'react';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import toUpper from 'lodash/toUpper';
import { capitalize, find, round } from 'lodash';
import {
  CURRENCY_PRECISION,
  formatMoney,
  getCurrencySign,
} from '../../shared/utils/number';
import { hasPuzzle } from '../DashboardRoutes/utils';
import { useCompanyContext } from '../context/Context';
import HelpIcon from '../../css/icons/Help';
import IconTooltip from '../components/common/IconTooltip';

export { getDate, formatDate } from '../utils/date';
export * from '../../shared/utils/number';
export {
  digError,
  parseGraphQLError,
  showGraphQLError,
  processError,
} from '../../shared/utils/error';

export const CSRF_TOKEN = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute('content');

export const isIterableArray = (array) =>
  Array.isArray(array) && !!array.length;

export const getPaginatedRecords = (currentPage, pageSize, records) => {
  const totalItems = records.length;
  if (totalItems) {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalItems);
    return records.slice(startIndex, endIndex);
  }
  return [];
};

export const getObjectValue = (obj, key, defaultValue) =>
  obj ? obj[key] : defaultValue;

//= ==============================
// Cookie
//= ==============================
export const getCookieValue = (name) => {
  const value = document.cookie.match(`(^|[^;]+)\\s*${name}\\s*=\\s*([^;]+)`);
  return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {
  const date = new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = `; expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value}${expires}; path=/`;
};

export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map((route) => {
    if (route.children) {
      return route.children.map((item) => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });

  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};

export const copyToClipBoard = (textFieldRef) => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand('copy');
};

//= ==============================
// Breakpoints
//= ==============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1280, // Updated this to align with adapt MD breakpoint, so nav transition is smooth
  xxl: 1540,
};

export const numberFormatter = (number, fixed = 2) => {
  const num = Math.abs(Number(number));
  if (num >= 1.0e9) {
    // Nine Zeroes for Billions
    return `${(num / 1.0e9).toFixed(fixed).replace(/(\.0+|0+)$/, '')}B`;
  }
  if (num >= 1.0e6) {
    // Six Zeroes for Millions
    return `${(num / 1.0e6).toFixed(fixed).replace(/(\.0+|0+)$/, '')}M`;
  }
  if (num >= 1.0e3) {
    // Three Zeroes for Thousands
    return `${(num / 1.0e3).toFixed(fixed).replace(/(\.0+|0+)$/, '')}k`;
  }

  return num.toFixed(fixed).replace(/(\.0+|0+)$/, '');
};

//= ==============================
// Colors
//= ==============================
export const hexToRgb = (hexValue) => {
  const hex = hexValue.indexOf('#') === 0 ? hexValue.substring(1) : hexValue;
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b),
  );
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const colors = [
  '#4565D7',
  '#39AEDC',
  '#14CAD6',
  '#00CC95',
  '#78CD35',
  '#F4D00E',
  '#FFAD0E',
  '#5646BE',
  '#9E63FF',
  '#D170FF',
  '#F566C4',
  '#F56671',
  '#F25C3C',
  '#FF820E',
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343',
];

export const pieChartOtherColor = '#245C4E';
export const pieChartColors = ['#519BCD', '#E9E8FF', ...colors];

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) =>
  `rgba(${hexToRgb(color)},${alpha})`;
export const randomColor = () =>
  colors[Math.floor(Math.random() * colors.length)];

export const randomColors = (numColors) => {
  // Shuffle array
  const shuffled = colors.sort(() => 0.5 - Math.random());

  // Get sub-array of first n elements after shuffled
  return shuffled.slice(0, numColors);
};

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000',
};

export const darkGrays = {
  white: '#fff',
  1100: '#f9fafd',
  1000: '#edf2f9',
  900: '#d8e2ef',
  800: '#b6c1d2',
  700: '#9da9bb',
  600: '#748194',
  500: '#5e6e82',
  400: '#4d5969',
  300: '#344050',
  200: '#232e3c',
  100: '#0b1727',
  black: '#000',
};

export const getGrays = (isDark) => (isDark ? darkGrays : grays);

export const rgbColors = colors.map((color) => rgbColor(color));
export const rgbaColors = colors.map((color) => rgbaColor(color));

//= ==============================
// Echarts
//= ==============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2,
});

//= ==============================
// E-Commerce
//= ==============================
export const calculateSale = (base, less = 0, fix = 2) =>
  (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find((item) => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);

//= ==============================
// Store
//= ==============================
export const getItemFromStore = (key, defaultValue, store = localStorage) =>
  JSON.parse(store.getItem(key)) || defaultValue;
export const setItemToStore = (key, payload, store = localStorage) =>
  store.setItem(key, JSON.stringify(payload));
export const getStoreSpace = (store = localStorage) =>
  parseFloat(
    (
      escape(encodeURIComponent(JSON.stringify(store))).length /
      (1024 * 1024)
    ).toFixed(2),
  );

const SUFFIX_CONFIGS_BY_CURRENCY = {
  INR: [
    { magnitude: 12, suffix: 'lakh cr' },
    { magnitude: 7, suffix: 'cr' },
    { magnitude: 5, suffix: 'L' },
  ],
  USD: [
    { magnitude: 12, suffix: 'T' },
    { magnitude: 9, suffix: 'B' },
    { magnitude: 6, suffix: 'M' },
    { magnitude: 3, suffix: 'k' },
  ],
};

const LOCALE_BY_CURRENCY = {
  USD: 'en-US',
  INR: 'en-IN',
};

const getSuffixConfig = (dollars, currency) => {
  const suffixConfigsForCurrency =
    SUFFIX_CONFIGS_BY_CURRENCY[currency] || SUFFIX_CONFIGS_BY_CURRENCY.USD;

  return find(
    suffixConfigsForCurrency,
    ({ magnitude }) => round(dollars) >= 10 ** magnitude,
  );
};

// Abbreviates and prefixes with a currency symbol (e.g. 250000 -> $250K). Adapted from the Venture
// monolith's `frontend/fundraising/lib/money.tsx#formatAbbreviated` function.
export const formatMoneyAbbreviated = (dollars, currency = 'USD') => {
  const currencySign = getCurrencySign(currency);
  const suffixConfig = getSuffixConfig(dollars, currency);

  if (!suffixConfig) return `${currencySign}${dollars}`;

  const formattedAmount = (
    dollars /
    10 ** suffixConfig.magnitude
  ).toLocaleString(LOCALE_BY_CURRENCY[currency], {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  });

  return `${currencySign}${formattedAmount}${suffixConfig.suffix}`;
};

export const formatPhone = (obj) => {
  if (obj && obj.number) {
    return `+${obj.countryCode} ${obj.number}`;
  }
  return '—';
};

export const formatMoneyObject = (moneyObject, options = {}) => {
  if (!moneyObject) {
    return '—';
  }
  return formatMoney(moneyObject.dollars, moneyObject.currency, {
    precision: options.precision ?? CURRENCY_PRECISION.DEFAULT,
    forcePrecision: options.forcePrecision || false,
    showCurrencyCode: options.showCurrencyCode || false,
  });
};

export const betterMoneyObjectFormat = (moneyObj) => {
  if (!moneyObj) {
    return '—';
  }
  return moneyObj.betterFormat;
};

const numberFormatIntl = (number, factor) =>
  new Intl.NumberFormat(undefined).format(
    Math.round(number * factor * 100 + Number.EPSILON) / factor,
  );

export const formatPercentage = (value) => {
  if (!value) {
    return '—';
  }
  const factor = value <= 0.0001 ? 1000 : 100;
  return numberFormatIntl(value, factor);
};

export const formatFixedPercentage = (value, decimal = 2) => {
  if (!value) {
    return '—';
  }
  return Number(value * 100).toFixed(decimal);
};

export const formatPercentageWithSign = (value, factor = 100) => {
  if (!value) {
    return '—';
  }
  return `${numberFormatIntl(value, factor)}%`;
};

export const percentageWithSign = (value) => {
  if (!value) {
    return '—';
  }
  return `${value}%`;
};

export const formatTitle = (s) => {
  if (s === 'full_time') return 'Full-time';
  if (s === 'part_time') return 'Part-time';

  return startCase(toLower(s));
};

export const capitalizeText = (s) => capitalize(startCase(s));

export const formatAbbrevation = (string) => toUpper(string);
export const formatBoolean = (str) => {
  if (!str) return false;

  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
};

export const separateAbbrevationFromTitle = (stringArr) => {
  const capitalizeArray = [];
  const nonFormattedArr = [];
  // eslint-disable-next-line array-callback-return
  stringArr.map((str) => {
    if (['ceo', 'cto', 'cfo'].includes(toLower(str))) {
      capitalizeArray.push(str.toUpperCase());
      return;
    }

    nonFormattedArr.push(str);
  });
  return { capitalizeArray, nonFormattedArr };
};

export const formatOfficeTitle = (stringArr) => {
  const { capitalizeArray, nonFormattedArr } = separateAbbrevationFromTitle(
    stringArr,
  );
  const formatedArr = capitalizeArray.concat(
    nonFormattedArr.map((str) => formatTitle(str)),
  );

  return formatedArr.join(', ');
};

export const getOptions = (array, idKey = 'id', nameKey = 'name') =>
  array.map((item) => ({ value: item[idKey], label: item[nameKey] }));

export const formatOptionsWithNames = (
  data,
  idKey = 'id',
  nameKey = 'name',
  stakeholderKey = 'stakeholder',
) =>
  data.map((item) => {
    const id = item[idKey];
    const name = item[nameKey];
    const stakeholderName =
      stakeholderKey && item[stakeholderKey]
        ? item[stakeholderKey]?.name ||
          item[stakeholderKey]?.stakeholder?.name ||
          item[stakeholderKey]?.shareholder?.entityName ||
          item[stakeholderKey]?.entityName ||
          ''
        : '';

    return {
      value: id,
      label: stakeholderName ? `${name} (${stakeholderName})`.trim() : name,
    };
  });

export const addKeyToOptions = (array) =>
  array.map((item) => ({ key: item.value, ...item }));

export const getOptionsFromArray = (array) =>
  array.map((item) => ({ value: item, label: item }));

export const getOptionsNameMap = (array) =>
  array.reduce((acc, item) => {
    acc[item.value] = item.label;
    return acc;
  }, {});

export const downloadFileByUrl = (url, filename) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const downloadBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  downloadFileByUrl(url, filename);
};

export const getCountryName = (name, code) => {
  if (name) {
    if (name !== code) {
      return `${name} (${code})`;
    }
  }

  return code || 'Unknown';
};

export const sortAsc = (array = [], key) =>
  [...array].sort((a, b) => a[key] - b[key]);

export const sortDesc = (array = [], key) =>
  [...array].sort((a, b) => b[key] - a[key]);

export const compactArray = (...rest) => rest.filter((x) => x);

export const joinArray = (...rest) => compactArray(...rest).join(' ');

// Moving lodash function to JS
export const noop = () => {
  // No operation performed.
};

export const getEmployeeName = (employee, emptyValue = '') => {
  if (employee) {
    const { user } = employee;
    if (user) {
      return user.name;
    }
  }
  return emptyValue;
};

export const formatName = ({ firstName, lastName }) =>
  joinArray(firstName, lastName);

export const PHONE_MASK = '** **********';

export const ADDRESS_MASK = (
  <span>
    ********** ********
    <br />
    ******** *** *****
  </span>
);

export const formatWithPattern = (numStr, pattern) => {
  if (numStr) {
    let hashCount = 0;
    const formattedNumberAry = pattern.split('');
    for (let i = 0, ln = pattern.length; i < ln; i += 1) {
      if (pattern[i] === '#') {
        formattedNumberAry[i] = numStr[hashCount];
        hashCount += 1;
      }
    }
    return formattedNumberAry.join('');
  }
  return '—';
};

export const copyTextToClipboard = (text) =>
  navigator.clipboard.writeText(text);

export const maskCharacter = (str, n = 4, mask = '*') =>
  `${str}`.slice(0, -n).replace(/./g, mask) + `${str}`.slice(-n);

export const formatRole = (role) => {
  if (!role) {
    return '-';
  }

  return formatTitle(role);
};

export const formatHasFdicAccess = (hasFdicAccess) => {
  const context = useCompanyContext();
  if (!hasPuzzle(context)) {
    return (
      <>
        <HelpIcon id="hasFdicAccessTooltip" />
        <IconTooltip
          target="hasFdicAccessTooltip"
          className="tooltip-secondary"
          title={
            <div className="text-left">
              <p>Enable Analytics to access FDIC coverage.</p>
            </div>
          }
        />
      </>
    );
  }

  return hasFdicAccess ? 'Yes' : 'No';
};
