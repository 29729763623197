import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

const IconTooltip = ({ target, placement, title, ...rest }) => (
  <UncontrolledTooltip placement={placement} target={target} {...rest}>
    {title}
  </UncontrolledTooltip>
);

IconTooltip.propTypes = {
  target: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};

IconTooltip.defaultProps = {
  placement: 'top',
};

export default IconTooltip;
