import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import L10n, { L10N_DEFAULT_VALUES } from '../L10n';

const L10nContext = createContext({
  ...L10N_DEFAULT_VALUES,
});

window.L10n = L10N_DEFAULT_VALUES;

export const L10nProvider = ({ country, children }) => {
  const localization = L10n[country] || {};
  const contextValues = {
    ...L10N_DEFAULT_VALUES,
    country,
    ...localization,
  };

  window.L10n = contextValues;

  return (
    <L10nContext.Provider value={contextValues}>
      {children}
    </L10nContext.Provider>
  );
};

L10nProvider.defaultProps = {
  country: 'IN',
};

L10nProvider.propTypes = {
  country: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default L10nContext;
